import Row from "react-bootstrap/Row";
import {Button, Card, Col} from "react-bootstrap";
import Container from "react-bootstrap/Container";

function HomePage({onStartButtonClicked, onHereButtonClicked}){

    return (
        <Container className="center-screen border-2 d-flex flex-column">
            <Row>
                <Col className="mx-auto my-auto">
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-center"><h2>Welcome to stat-analyse.com</h2>
                            </Card.Title>
                            <p>Here, we aim to guide you to identifying the statistical test you need to
                                perform on your
                                healthcare science data.
                                <br/>
                                There are also some simple visual guides on how to perform the statistical
                                tests.
                            </p>
                            <p>Statistical tests for healthcare scientist mainly will investigate the
                                difference between
                                groups of data or the association between variables in data.<br/>
                                Whichever direction your analysis focuses on, there are 2 important
                                questions you
                                would need to know before using this guide
                            </p>
                            <ol>
                                <li> The aim of your project</li>
                                <li> What are you measuring? E.g. to compare the difference in the heights
                                    of in the
                                    Physics department first year students of two universities, we would
                                    measure
                                    the students heights (in mm, cm, or feet).
                                </li>
                            </ol>
                            <p>To use this web tool as a guide, please click the start button
                                <br/>
                                If you are sure of the Statistical test you need to perform,
                                please select the appropriate statistical test <Button
                                    onClick={onHereButtonClicked}>here</Button>
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
                <Row className="mt-5 justify-content-center">
                    <Button className="btn-circle" onClick={onStartButtonClicked}>START</Button>
                </Row>
            </Row>
        </Container>
        )

}

export default HomePage