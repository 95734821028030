import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

function DropDown({
                      dropdownTitle = "",
                      dropdownData = [{}],
                      onChosenDataTypeChanged = () => {},
                  }) {

    return (

        <Container>
            <Row>
                <Form.Select size="lg" onChange={(event) => {
                    onChosenDataTypeChanged(event)
                }}>
                    <option>{dropdownTitle}</option>
                    {
                        dropdownData.map((entry) =>
                            <option value={entry[Object.keys(entry)[0]]}>{entry[Object.keys(entry)[1]]}</option>
                        )
                    }
                </Form.Select>
            </Row>
        </Container>
        // <Form.Select>
        //     <option>Types of data</option>
        //     <option value="QUALITATIVE_DATA_TYPE">Qualitative data (Often categorical data)</option>
        //     <option value="QUANTITATIVE_DATA_TYPE">Quantitative data (Numerical in nature) Could be discrete or
        //         continuous
        //     </option>
        // </Form.Select>

    )

}

export default DropDown;