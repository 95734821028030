import CardItem from "./CardItem";
import DropDown from "./DropDown";
import {buttonText, questionData} from "./DataPool";
import {useState} from "react";


function Question({
                      currentCardKey = "",
                      measureTextChangeHandler = () => {},
                      aimTextChangeHandler = () => {},
                      onChosenDataTypeChanged = () => {},
                      onButtonClicked = () => {},
                      shouldShowBackButton = false
                  }) {

    // const [textToShow, setTextToShow] = useState("")
    //
    // if(currentCardKey === "AIM_OF_PROJECT_QUES"){
    //     setTextToShow(sessionStorage.getItem("aimText"))
    // } else if(currentCardKey === "MEASURING_QUES") {
    //     setTextToShow(sessionStorage.getItem("measureText"))
    // }

    const questionsObj = {
        "AIM_OF_PROJECT_QUES": <CardItem mainText={questionData["AIM_OF_PROJECT_QUES"]}
            // textAreaText={appState.projectAimTextAreaValue.value}
                                         shouldShowBackButton={shouldShowBackButton}//sessionStorage.getItem("aimText")
                                         // textAreaText={sessionStorage.getItem("aimText")}
                                         textAreaText={sessionStorage.getItem("aimText")}
                                         buttonTextArray={[buttonText["NEXT"]]}
                                         onTextAreaChanged={aimTextChangeHandler}
                                         onButtonClick={onButtonClicked}/>,

        "MEASURING_QUES": <CardItem mainText={questionData["MEASURING_QUES"]}
            // textAreaText={appState.measuringTextAreaValue.value}
                                    shouldShowBackButton={shouldShowBackButton}
                                    textAreaText={sessionStorage.getItem("measureText")}
                                    buttonTextArray={[buttonText["NEXT"]]}
                                    onTextAreaChanged={measureTextChangeHandler}
                                    onButtonClick={onButtonClicked}/>,

        "COMPLETED_DATA_COLLECTION_QUES": <CardItem mainText={questionData["COMPLETED_DATA_COLLECTION_QUES"]}
                                                    shouldShowBackButton={shouldShowBackButton}
                                                    shouldShowTextArea={false}
                                                    buttonTextArray={[buttonText["YES"], buttonText["NO"]]}
                                                    onButtonClick={onButtonClicked}/>,

        "COMPLETE_DATA_COLLECTION": <CardItem mainText={questionData["COMPLETE_DATA_COLLECTION"]}
                                              shouldShowBackButton={shouldShowBackButton}
                                              shouldShowTextArea={false}
                                              shouldShowNextButton={false}
                                              onButtonClick={onButtonClicked}/>,

        "QUANTITATIVE_OPTIONS": <CardItem mainText={questionData["QUANTITATIVE_OPTIONS"]}
                                          shouldShowBackButton={shouldShowBackButton}
                                          shouldShowTextArea={false}
                                          buttonTextArray={[buttonText["DISCRETE"], buttonText["CONTINUOUS"]]}
                                          onButtonClick={onButtonClicked}/>,

        "DATA_TYPE_QUES": <CardItem mainText={questionData["DATA_TYPE_QUES"]}
                                    shouldShowBackButton={shouldShowBackButton}
                                    shouldShowTextArea={false}
                                    buttonTextArray={[buttonText["QUANTITATIVE"], buttonText["QUALITATIVE"], buttonText["I_DONT_KNOW"]]}
                                    onButtonClick={onButtonClicked}/>,

        "SELECT_DATA_TYPE": <CardItem mainText={questionData["SELECT_DATA_TYPE"]}
                                      shouldShowBackButton={shouldShowBackButton}
                                      shouldShowTextArea={false}
                                      shouldShowDataTypeDropdown={true}
                                      dropDownComponent={<DropDown dropdownTitle={"Select a data type"} dropdownData={[
                                          {
                                              optionValue: "QUALITATIVE_DATA_TYPE",
                                              value: "Qualitative data (Often categorical data)"
                                          },
                                          {
                                              optionValue: "QUANTITATIVE_DATA_TYPE",
                                              value: "Quantitative data (Numerical in nature) Could be discrete or continuous"
                                          },
                                      ]} onChosenDataTypeChanged={onChosenDataTypeChanged}/>}
                                      buttonTextArray={[buttonText["NEXT"]]}
                                      onButtonClick={onButtonClicked}/>,

        "NORMALLY_DISTRIBUTED_QUES": <CardItem mainText={questionData["NORMALLY_DISTRIBUTED_QUES"]}
                                               shouldShowBackButton={shouldShowBackButton}
                                               shouldShowTextArea={false}
                                               buttonTextArray={[buttonText["YES"], buttonText["NO"], buttonText["I_DONT_KNOW"]]}
                                               onButtonClick={onButtonClicked}/>,

        "DO_PARAMETRIC_TEST": <CardItem mainText={questionData["DO_PARAMETRIC_TEST"]}
                                        shouldShowBackButton={shouldShowBackButton}
                                        shouldShowTextArea={false}
                                        buttonTextArray={[buttonText["NEXT"]]}
                                        videoUrl={"https://www.youtube.com/embed/IXkcfzZ47dA"}
                                        onButtonClick={onButtonClicked}/>,

        "DO_NON_PARAMETRIC_TEST": <CardItem mainText={questionData["DO_NON_PARAMETRIC_TEST"]}
                                            shouldShowBackButton={shouldShowBackButton}
                                            shouldShowTextArea={false}
                                            buttonTextArray={[buttonText["NEXT"]]}
                                            onButtonClick={onButtonClicked}/>,

        "DO_T-TEST": <CardItem mainText={questionData["DO_T-TEST"]}
                               shouldShowBackButton={shouldShowBackButton}
                               shouldShowTextArea={false}
                               buttonTextArray={[buttonText["NEXT"]]}
                               onButtonClick={onButtonClicked}/>,

        "DO_ANOVA": <CardItem mainText={questionData["DO_ANOVA"]}
                              shouldShowBackButton={shouldShowBackButton}
                              shouldShowTextArea={false}
                              buttonTextArray={[buttonText["NEXT"]]}
                              onButtonClick={onButtonClicked}/>,

        "DO_ONE_WAY_ANOVA": <CardItem mainText={questionData["DO_ONE_WAY_ANOVA"]}
                                      shouldShowBackButton={shouldShowBackButton}
                                      shouldShowTextArea={false}
                                      buttonTextArray={[buttonText["NEXT"]]}
                                      onButtonClick={onButtonClicked}/>,

        "DO_TWO_WAY_ANOVA": <CardItem mainText={questionData["DO_TWO_WAY_ANOVA"]}
                                      shouldShowBackButton={shouldShowBackButton}
                                      shouldShowTextArea={false}
                                      buttonTextArray={[buttonText["NEXT"]]}
                                      onButtonClick={onButtonClicked}/>,

        "DO_TWO_WAY_REPEATED_ANOVA": <CardItem mainText={questionData["DO_TWO_WAY_REPEATED_ANOVA"]}
                                               shouldShowBackButton={shouldShowBackButton}
                                               shouldShowTextArea={false}
                                               buttonTextArray={[buttonText["NEXT"]]}
                                               onButtonClick={onButtonClicked}/>,

        "DO_REPEATED_ANOVA": <CardItem mainText={questionData["DO_REPEATED_ANOVA"]}
                                       shouldShowBackButton={shouldShowBackButton}
                                       shouldShowTextArea={false}
                                       buttonTextArray={[buttonText["NEXT"]]}
                                       onButtonClick={onButtonClicked}/>,

        "DO_MANOVA": <CardItem mainText={questionData["DO_MANOVA"]}
                               shouldShowBackButton={shouldShowBackButton}
                               shouldShowTextArea={false}
                               buttonTextArray={[buttonText["NEXT"]]}
                               onButtonClick={onButtonClicked}/>,

        "TYPE_OF_VARIABLES_QUES": <CardItem mainText={questionData["TYPE_OF_VARIABLES_QUES"]}
                                            shouldShowBackButton={shouldShowBackButton}
                                            shouldShowTextArea={false}
                                            buttonTextArray={[buttonText["INDEPENDENT"], buttonText["DEPENDENT"]]}
                                            onButtonClick={onButtonClicked}/>,

        "HOW_MANY_INDEPENDENT_VARIABLES_QUES": <CardItem mainText={questionData["HOW_MANY_INDEPENDENT_VARIABLES_QUES"]}
                                                         shouldShowBackButton={shouldShowBackButton}
                                                         shouldShowTextArea={false}
                                                         buttonTextArray={[buttonText["ONE"], buttonText["TWO"]]}
                                                         onButtonClick={onButtonClicked}/>,

        "DATA_DEPENDENT_QUES": <CardItem mainText={questionData["DATA_DEPENDENT_QUES"]}
                                         shouldShowBackButton={shouldShowBackButton}
                                         shouldShowTextArea={false}
                                         buttonTextArray={[buttonText["YES"], buttonText["NO"]]}
                                         onButtonClick={onButtonClicked}/>,

        "VARIABLES_DEPENDANT_QUES": <CardItem mainText={questionData["VARIABLES_DEPENDANT_QUES"]}
                                              shouldShowBackButton={shouldShowBackButton}
                                              shouldShowTextArea={false}
                                              buttonTextArray={[buttonText["YES"], buttonText["NO"]]}
                                              onButtonClick={onButtonClicked}/>,

        "MULTIPLE_DEPENDENT_VARIABLES_QUES": <CardItem mainText={questionData["MULTIPLE_DEPENDENT_VARIABLES_QUES"]}
                                                       shouldShowBackButton={shouldShowBackButton}
                                                       shouldShowTextArea={false}
                                                       buttonTextArray={[buttonText["YES"], buttonText["NO"]]}
                                                       onButtonClick={onButtonClicked}/>,

        "ARE_YOU_MEASURING_OVER_TIME": <CardItem mainText={questionData["ARE_YOU_MEASURING_OVER_TIME"]}
                                                 shouldShowBackButton={shouldShowBackButton}
                                                 shouldShowTextArea={false}
                                                 buttonTextArray={[buttonText["YES"], buttonText["NO"]]}
                                                 onButtonClick={onButtonClicked}/>,

        "QUALITATIVE_OPTIONS": <CardItem mainText={questionData["QUALITATIVE_OPTIONS"]}
                                         shouldShowBackButton={shouldShowBackButton}
                                         shouldShowTextArea={false}
                                         buttonTextArray={[buttonText["MAKING_COMPARISON"], buttonText["DETERMINING_ASSOCIATION"]]}
                                         onButtonClick={onButtonClicked}/>,

        "QUALITATIVE_ASSOCIATION_OPTIONS": <CardItem mainText={questionData["QUALITATIVE_ASSOCIATION_OPTIONS"]}
                                                     shouldShowBackButton={shouldShowBackButton}
                                                     shouldShowTextArea={false}
                                                     buttonTextArray={[buttonText["COMPARING_CATEGORICAL"], buttonText["INVESTIGATING_ASSOCIATION"]]}
                                                     onButtonClick={onButtonClicked}/>,

        "DO_REGRESSION_TEST": <CardItem mainText={questionData["DO_REGRESSION_TEST"]}
                                        shouldShowBackButton={shouldShowBackButton}
                                        shouldShowTextArea={false}
                                        buttonTextArray={[buttonText["NEXT"]]}
                                        onButtonClick={onButtonClicked}/>,

        "DO_CHI_SQUARE_DIFFERENCE_TEST": <CardItem mainText={questionData["DO_CHI_SQUARE_DIFFERENCE_TEST"]}
                                                   shouldShowBackButton={shouldShowBackButton}
                                                   shouldShowTextArea={false}
                                                   buttonTextArray={[buttonText["NEXT"]]}
                                                   onButtonClick={onButtonClicked}/>,

        "DO_CHI_SQUARE_ASSOCIATION_TEST": <CardItem mainText={questionData["DO_CHI_SQUARE_ASSOCIATION_TEST"]}
                                                    shouldShowBackButton={shouldShowBackButton}
                                                    shouldShowTextArea={false}
                                                    buttonTextArray={[buttonText["NEXT"]]}
                                                    onButtonClick={onButtonClicked}/>,

        "DO_DEPENDENT_T-TEST": <CardItem mainText={questionData["DO_DEPENDENT_T-TEST"]}
                                         shouldShowBackButton={shouldShowBackButton}
                                         shouldShowTextArea={false}
                                         buttonTextArray={[buttonText["NEXT"]]}
                                         onButtonClick={onButtonClicked}/>,

        "DO_INDEPENDENT_T-TEST": <CardItem mainText={questionData["DO_INDEPENDENT_T-TEST"]}
                                           shouldShowBackButton={shouldShowBackButton}
                                           shouldShowTextArea={false}
                                           buttonTextArray={[buttonText["NEXT"]]}
                                           onButtonClick={onButtonClicked}/>,

        "DO_MANN_WHITNEY_TEST": <CardItem mainText={questionData["DO_MANN_WHITNEY_TEST"]}
                                          shouldShowBackButton={shouldShowBackButton}
                                          shouldShowTextArea={false}
                                          buttonTextArray={[buttonText["NEXT"]]}
                                          onButtonClick={onButtonClicked}/>,

        "DO_WILCOXON_TEST": <CardItem mainText={questionData["DO_WILCOXON_TEST"]}
                                      shouldShowBackButton={shouldShowBackButton}
                                      shouldShowTextArea={false}
                                      buttonTextArray={[buttonText["NEXT"]]}
                                      onButtonClick={onButtonClicked}/>,

        "DO_FRIEDMAN_TEST": <CardItem mainText={questionData["DO_FRIEDMAN_TEST"]}
                                      shouldShowBackButton={shouldShowBackButton}
                                      shouldShowTextArea={false}
                                      buttonTextArray={[buttonText["NEXT"]]}
                                      onButtonClick={onButtonClicked}/>,

        "DO_KRUSKAL-WALLACE_TEST": <CardItem mainText={questionData["DO_KRUSKAL-WALLACE_TEST"]}
                                             shouldShowBackButton={shouldShowBackButton}
                                             shouldShowTextArea={false}
                                             buttonTextArray={[buttonText["NEXT"]]}
                                             onButtonClick={onButtonClicked}/>,

        "DO_NORMALITY_TEST": <CardItem mainText={questionData["DO_NORMALITY_TEST"]}
                                       shouldShowBackButton={shouldShowBackButton}
                                       shouldShowTextArea={false}
                                       buttonTextArray={[buttonText["NEXT"]]}
                                       onButtonClick={onButtonClicked}/>,

        "OBTAIN_P_VALUE": <CardItem mainText={questionData["OBTAIN_P_VALUE"]}
                                    shouldShowBackButton={shouldShowBackButton}
                                    shouldShowTextArea={false}
                                    buttonTextArray={[buttonText["P_GREATER_THAN_0.05"], buttonText["P_LESS_THAN_0.05"]]}
                                    onButtonClick={onButtonClicked}/>,

        "GROUP_COUNT_QUES": <CardItem mainText={questionData["GROUP_COUNT_QUES"]}
                                      shouldShowBackButton={shouldShowBackButton}
                                      shouldShowTextArea={false}
                                      buttonTextArray={[buttonText["TWO_GROUPS"], buttonText["MORE_THAN_TWO_GROUPS"]]}
                                      onButtonClick={onButtonClicked}/>,

        "NO_SIGNIFICANT_DIFFERENCE": <CardItem mainText={questionData["NO_SIGNIFICANT_DIFFERENCE"]}
                                               shouldShowBackButton={shouldShowBackButton}
                                               shouldShowTextArea={false}
                                               onButtonClick={onButtonClicked}/>,

        "SIGNIFICANT_DIFFERENCE": <CardItem mainText={questionData["SIGNIFICANT_DIFFERENCE"]}
                                            shouldShowBackButton={shouldShowBackButton}
                                            shouldShowTextArea={false}
                                            onButtonClick={onButtonClicked}/>,

        "COMPLETE": <CardItem mainText={questionData["COMPLETE"]}
                              shouldShowBackButton={shouldShowBackButton}
                              shouldShowTextArea={false}
                              onButtonClick={onButtonClicked}/>,
    }

    return (
        questionsObj[currentCardKey]
    )


}

export default Question

