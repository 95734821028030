import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useState} from "react";

function CardItem({
                      mainText,
                      shouldShowTextArea = true,
                      onTextAreaChanged,
                      textAreaText,
                      shouldShowBackButton = true,
                      shouldShowNextButton = true,
                      shouldShowDataTypeDropdown = false,
                      dropDownComponent = [{}],
                      videoUrl = "",
                      buttonTextArray = [],
                      onButtonClick
                  }
) {

    const [currentTextAreaValue, setCurrentTextAreaValue] = useState(textAreaText)

    let internalOnTextAreaChange = (event) => {
        setCurrentTextAreaValue(event.target.value)
        onTextAreaChanged(event)
    }

    const handleTextArea = () => {
        if (shouldShowTextArea) {
            // setTextAreaValue("")
        }
    }

    console.log({currentTextAreaValue})
    // console.log(`sessionStorage.getItem("aimText")?.value: ${sessionStorage.getItem("aimText")}`)
    // console.log(`sessionStorage.getItem("measureText")?.value: ${sessionStorage.getItem("measureText")}`)

    const cardObject = <CardItem
        mainText={mainText}
        // textAreaText={mainText === "What are you measuring?" ? sessionStorage.getItem("measureText") : sessionStorage.getItem("aimText")}
        textAreaText={currentTextAreaValue}
        // textAreaText={sessionStorage.getItem("aimText")}
        shouldShowTextArea={shouldShowTextArea}
        onTextAreaChanged={onTextAreaChanged}
        shouldShowNextButton={shouldShowNextButton}
        shouldShowBackButton={shouldShowBackButton}
        videoUrl={videoUrl}
        shouldShowDataTypeDropdown={shouldShowDataTypeDropdown}
        dropDownComponent={dropDownComponent}
        buttonTextArray={buttonTextArray}
        onButtonClick={onButtonClick}
    />

    return (
        <Container className="p-5">
            <Row>
                <Col xs={2} className="m-auto">
                    {
                        shouldShowBackButton ? <Button onClick={() => {
                            handleTextArea()
                            onButtonClick("Back", cardObject)
                        }} variant="primary" size="lg" className="w-100">
                            Back
                        </Button> : null
                    }
                </Col>

                <Col xs={8}>
                    <Row className="border-0">
                        <Card className="border-0">
                            <Card.Body>
                                <Card.Text className="text-center">
                                    <h3>{mainText}</h3>
                                </Card.Text>

                                {shouldShowTextArea ?
                                    <Form className="">
                                        <Form.Control value={currentTextAreaValue}
                                                      onChange={internalOnTextAreaChange}
                                                      className="no-resize"
                                                      as="textarea"
                                                      key={mainText}
                                                      rows={3}
                                                      size="lg"/>
                                    </Form> : null}
                            </Card.Body>
                        </Card>
                    </Row>
                    {videoUrl ?
                        <Row>
                            <iframe height="400"
                                    src={videoUrl}>
                            </iframe>
                        </Row> : null
                    }
                    <Row className="text-center mt-2">
                        {shouldShowDataTypeDropdown ? dropDownComponent : null}
                    </Row>
                    <Row className="text-center mt-2">
                        {
                            buttonTextArray.map((buttonText) =>
                                <Col key={buttonText} className="">
                                    <Button onClick={() => {
                                        handleTextArea()
                                        onButtonClick(buttonText, cardObject)
                                    }} variant="primary" size="lg" className="w-100">
                                        {buttonText}
                                    </Button>
                                </Col>
                            )
                        }
                    </Row>
                </Col>

                <Col xs={2} className="m-auto">
                </Col>
            </Row>
        </Container>
    );
}

export default CardItem;