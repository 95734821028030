// noinspection JSCheckFunctionSignatures

import './App.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Question from "./components/Questions";
import {buttonText, questionData} from "./components/DataPool";
import HomePage from "./components/HomePage";

function App() {

    const [appState, setAppState] = useState(
        {
            questions: [],
            chosenDataType: "",
            independentVariablesCount: 0,
            isDataNormallyDistributed: false,
            projectAimTextAreaValue: sessionStorage.getItem("aimText"),
            measuringTextAreaValue: sessionStorage.getItem("measureText"),
        }
    )

    const [currentCard, setCurrentCard] = useState(); // Set initial card to show
    const [startButtonIsClicked, setStartButtonIsClicked] = useState(false)
    const [hereButtonIsClicked, setHereButtonIsClicked] = useState(false)

    const aimTextChangeHandler = (event) => setAimText(event.target.value)

    const measureTextChangeHandler = (event) => setMeasureText(event.target.value)

    const addToQuestion = (questionObject) => {
        setAppState(prevState => {
            questionLength.current = appState.questions.length
            return {...prevState, questions: [...prevState.questions, questionObject]}
        })
    }

    const setIndependentVariables = (value) => {
        setAppState(prevState => {
            return {...prevState, independentVariablesCount: value}
        })
    }

    const setIsDataNormallyDistributed = (value) => {
        setAppState(prevState => {
            return {...prevState, isDataNormallyDistributed: value}
        })
    }

    const removeFromQuestionArray = () => {
        setAppState(prevState => {
            let questionsArray = prevState.questions
            questionsArray.pop()
            return {...prevState, questions: questionsArray}
        })
    }

    const setAimText = (value) => {
        sessionStorage.setItem("aimText", value)
        setAppState(prevState => {
            return {...prevState, projectAimTextAreaValue: value}
        })
    }

    const setMeasureText = (value) => {
        sessionStorage.setItem("measureText", value)
        setAppState(prevState => {
            return {...prevState, measuringTextAreaValue: value}
        })
    }

    let questionLength = useRef(0)
    useEffect(() => {
        questionLength.current = appState.questions.length
    })

    let chosenDataType = useRef("")
    useEffect(() => {
        chosenDataType.current = appState.chosenDataType
    })

    let lastCardInQuestionsArray = useRef({})
    useEffect(() => {
        console.log({lastCardInQuestionsArray})
        lastCardInQuestionsArray.current = appState.questions[appState.questions.length - 1]
    })

    let intVarCount = useRef(0)
    useEffect(() => {
        intVarCount.current = appState.independentVariablesCount
    })

    let isNormallyDistributed = useRef(false)
    useEffect(() => {
        isNormallyDistributed.current = appState.isDataNormallyDistributed
    })

    const onChosenDataTypeChanged = (event) => {
        const chosenValue = event.target.value;
        console.log(`value changed ${chosenValue}`)
        chosenDataType.current = chosenValue
    }

    const questionComponent = useRef()

    useEffect(()=>{
        questionComponent.current = (currentCardKey, textAreaText = "") => {
            console.log(`Question length inside: ${appState.questions.length}`)
            return <Question
                textAreaText={"ereer"}
                shouldShowBackButton={appState.questions.length > 0}
                measureTextChangeHandler={measureTextChangeHandler}
                onChosenDataTypeChanged={onChosenDataTypeChanged}
                aimTextChangeHandler={aimTextChangeHandler}
                onButtonClicked={onButtonClicked}
                currentCardKey={currentCardKey}/>
        }
    })

    const onButtonClicked = (_buttonText, previousCardComponent) => {

        const previousCard = previousCardComponent
        const previousCardMainText = previousCardComponent.props.mainText
        console.log({previousCard})
        console.log(`Previous card textArea text: ${previousCard.props.textAreaText}`)

        // When x button is clicked do something
        // eslint-disable-next-line default-case
        switch (_buttonText) {
            case buttonText["YES"]:
                // Check the main text that was on the card the button was on
                switch (previousCardMainText) {
                    case questionData["NORMALLY_DISTRIBUTED_QUES"]:
                        addToQuestion(previousCard)
                        setIsDataNormallyDistributed(true)
                        setCurrentCard(questionComponent.current("DO_PARAMETRIC_TEST"))
                        break

                    case questionData["TYPE_OF_VARIABLES_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("HOW_MANY_INDEPENDENT_VARIABLES_QUES"))
                        break

                    case questionData["COMPLETED_DATA_COLLECTION_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DATA_TYPE_QUES"))
                        break

                    case questionData["DATA_DEPENDENT_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DO_DEPENDENT_T-TEST"))
                        break

                    case questionData["MULTIPLE_DEPENDENT_VARIABLES_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DO_MANOVA"))
                        break

                    case questionData["VARIABLES_DEPENDANT_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DO_FRIEDMAN_TEST"))
                        break

                    case questionData["ARE_YOU_MEASURING_OVER_TIME"]:
                        if (intVarCount.current === 1) {
                            addToQuestion(previousCard)
                            setCurrentCard(questionComponent.current("DO_REPEATED_ANOVA"))
                        } else if (intVarCount.current === 2) {
                            addToQuestion(previousCard)
                            setCurrentCard(questionComponent.current("DO_TWO_WAY_REPEATED_ANOVA"))
                        }
                        break

                    default:
                        addToQuestion(previousCard)
                    // setCurrentCard(nextCard)
                }
                break

            case buttonText["NO"]:
                // eslint-disable-next-line default-case
                switch (previousCardMainText) {
                    case questionData["COMPLETED_DATA_COLLECTION_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("COMPLETE_DATA_COLLECTION"))
                        break

                    case questionData["NORMALLY_DISTRIBUTED_QUES"]:
                        setIsDataNormallyDistributed(false)
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DO_NON_PARAMETRIC_TEST"))
                        break

                    case questionData["DATA_DEPENDENT_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DO_INDEPENDENT_T"))
                        break

                    case questionData["VARIABLES_DEPENDANT_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DO_KRUSKAL-WALLACE_TEST"))
                        break

                    case questionData["ARE_YOU_MEASURING_OVER_TIME"]:
                        addToQuestion(previousCard)
                        if (intVarCount.current === 1) {
                            setCurrentCard(questionComponent.current("DO_ONE_WAY_ANOVA"))
                        } else if (intVarCount.current === 2) {
                            setCurrentCard(questionComponent.current("DO_TWO_WAY_ANOVA"))
                        }
                        break
                }
                break

            case buttonText["I_DONT_KNOW"]:
                // eslint-disable-next-line default-case
                switch (previousCardMainText) {
                    case questionData["NORMALLY_DISTRIBUTED_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DO_NORMALITY_TEST"))
                        break

                    case questionData["TYPES_OF_DATA"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DO_NORMALITY_TEST"))
                        break

                    case questionData["DATA_TYPE_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("SELECT_DATA_TYPE"))
                        break
                }
                break

            case buttonText["INDEPENDENT"]:
                // Check the main text that was on the card the button was on
                // eslint-disable-next-line default-case
                switch (previousCardMainText) {
                    case questionData["TYPE_OF_VARIABLES_QUES"]:
                        addToQuestion(previousCard)

                        if (isNormallyDistributed.current === false) {
                            setCurrentCard(questionComponent.current("DO_MANN_WHITNEY_TEST"))
                        } else {
                            setCurrentCard(questionComponent.current("HOW_MANY_INDEPENDENT_VARIABLES_QUES"))
                        }
                        break
                }
                break

            case buttonText["DEPENDENT"]:
                // eslint-disable-next-line default-case
                switch (previousCardMainText) {
                    case questionData["TYPE_OF_VARIABLES_QUES"]:
                        addToQuestion(previousCard)

                        if (isNormallyDistributed.current === false) {
                            setCurrentCard(questionComponent.current("DO_WILCOXON_TEST"))
                        } else {
                            setCurrentCard(questionComponent.current("MULTIPLE_DEPENDENT_VARIABLES_QUES"))
                        }
                        break
                }
                break

            case buttonText["ONE"]:
                // eslint-disable-next-line default-case
                switch (previousCardMainText) {
                    case questionData["HOW_MANY_INDEPENDENT_VARIABLES_QUES"]:
                        setIndependentVariables(1)
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("ARE_YOU_MEASURING_OVER_TIME"))
                        break
                }
                break

            case buttonText["TWO"]:
                // eslint-disable-next-line default-case
                switch (previousCardMainText) {
                    case questionData["HOW_MANY_INDEPENDENT_VARIABLES_QUES"]:
                        setIndependentVariables(2)
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("ARE_YOU_MEASURING_OVER_TIME"))
                        break
                }
                break

            case buttonText["TWO_GROUPS"]:
                addToQuestion(previousCard)

                if (isNormallyDistributed.current === true) {
                    setCurrentCard(questionComponent.current("DO_T-TEST"))
                } else {
                    setCurrentCard(questionComponent.current("TYPE_OF_VARIABLES_QUES"))
                }
                break

            case buttonText["MORE_THAN_TWO_GROUPS"]:
                addToQuestion(previousCard)

                if (isNormallyDistributed.current === true) {
                    setCurrentCard(questionComponent.current("DO_ANOVA"))
                } else {
                    setCurrentCard(questionComponent.current("VARIABLES_DEPENDANT_QUES"))
                }
                break

            case buttonText["BACK"]:
                if (questionLength.current !== 0) {
                    removeFromQuestionArray()
                    setCurrentCard(lastCardInQuestionsArray.current)
                }
                break

            case buttonText["NEXT"]:
                switch (previousCardMainText) {
                    case questionData["AIM_OF_PROJECT_QUES"]:
                        addToQuestion(previousCard)
                        // setAimText(previousCard.props.textAreaText)
                        setCurrentCard(questionComponent.current("MEASURING_QUES"))
                        break

                    case questionData["MEASURING_QUES"]:
                        addToQuestion(previousCard)
                        // setMeasureText(previousCard.props.textAreaText)
                        setCurrentCard(questionComponent.current("COMPLETED_DATA_COLLECTION_QUES"))
                        break

                    case questionData["SELECT_DATA_TYPE"]:
                        addToQuestion(previousCard)
                        // eslint-disable-next-line default-case
                        switch (chosenDataType.current) {
                            case "QUALITATIVE_DATA_TYPE":
                                setCurrentCard(questionComponent.current("QUALITATIVE_OPTIONS"))
                                break
                            case "QUANTITATIVE_DATA_TYPE":
                                setCurrentCard(questionComponent.current("QUANTITATIVE_OPTIONS"))
                                break
                        }
                        break

                    case questionData["COMPLETED_DATA_COLLECTION_QUES"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DATA_TYPE_QUEST"))
                        break

                    case questionData["DO_T-TEST"]:
                        // The user clicked the next button so add the card that user was on to the state
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("DATA_DEPENDENT_QUES"))
                        break

                    case questionData["DO_NORMALITY_TEST"]:
                        // The user clicked the next button so add the card that user was on to the state
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("NORMALLY_DISTRIBUTED_QUES"))
                        break

                    case questionData["DO_REGRESSION_TEST"]:
                    case questionData["DO_CHI_SQUARE_ASSOCIATION_TEST"]:
                    case questionData["DO_CHI_SQUARE_DIFFERENCE_TEST_TEST"]:
                    case questionData["DO_DEPENDENT_T-TEST"]:
                    case questionData["DO_INDEPENDENT_T-TEST"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("OBTAIN_P_VALUE"))
                        break

                    case questionData["DO_CHI_SQUARE_DIFFERENCE_TEST"]:
                    case questionData["DO_REPEATED_ANOVA"]:
                    case questionData["DO_ONE_WAY_ANOVA"]:
                    case questionData["DO_TWO_WAY_ANOVA"]:
                    case questionData["DO_MANOVA"]:
                    case questionData["DO_MANN_WHITNEY_TEST"]:
                    case questionData["DO_WILCOXON_TEST"]:
                    case questionData["DO_FRIEDMAN_TEST"]:
                    case questionData["DO_KRUSKAL-WALLACE_TEST"]:
                    case questionData["DO_TWO_WAY_REPEATED_ANOVA"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("OBTAIN_P_VALUE"))
                        break

                    case questionData["DO_ANOVA"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("TYPE_OF_VARIABLES_QUES"))
                        break

                    case questionData["DO_PARAMETRIC_TEST"]:
                    case questionData["DO_NON_PARAMETRIC_TEST"]:
                        addToQuestion(previousCard)
                        setCurrentCard(questionComponent.current("GROUP_COUNT_QUES"))
                        break

                    default:
                }
                break

            case buttonText["P_GREATER_THAN_0.05"]:
                addToQuestion(previousCard)
                setCurrentCard(questionComponent.current("NO_SIGNIFICANT_DIFFERENCE"))
                break

            case buttonText["P_LESS_THAN_0.05"]:
                addToQuestion(previousCard)
                setCurrentCard(questionComponent.current("SIGNIFICANT_DIFFERENCE"))
                break

            case buttonText["MAKING_COMPARISON"]:
                addToQuestion(previousCard)
                setCurrentCard(questionComponent.current("DO_CHI_SQUARE_DIFFERENCE_TEST"))
                break

            case buttonText["DETERMINING_ASSOCIATION"]:
                addToQuestion(previousCard)
                setCurrentCard(questionComponent.current("QUALITATIVE_ASSOCIATION_OPTIONS"))
                break

            case buttonText["COMPARING_CATEGORICAL"]:
                addToQuestion(previousCard)
                setCurrentCard(questionComponent.current("DO_CHI_SQUARE_ASSOCIATION_TEST"))
                break

            case buttonText["INVESTIGATING_ASSOCIATION"]:
                addToQuestion(previousCard)
                setCurrentCard(questionComponent.current("DO_REGRESSION_TEST"))
                break

            case buttonText["QUALITATIVE"]:
                addToQuestion(previousCard)
                setCurrentCard(questionComponent.current("QUALITATIVE_OPTIONS"))
                break

            case buttonText["QUANTITATIVE"]:
                addToQuestion(previousCard)
                setCurrentCard(questionComponent.current("QUANTITATIVE_OPTIONS"))
                break
            case buttonText["DISCRETE"]:
                addToQuestion(previousCard)
                setCurrentCard(questionComponent.current("NORMALLY_DISTRIBUTED_QUES"))
                break
            case buttonText["CONTINUOUS"]:
                break;
        }
    }

    const onStartButtonClick = () => {
        setStartButtonIsClicked(true)
        setCurrentCard(questionComponent.current("AIM_OF_PROJECT_QUES"))
        addToQuestion(questionComponent.current("AIM_OF_PROJECT_QUES"))
    }

    const onHereButtonClicked = () => {
        setHereButtonIsClicked(true)
        setCurrentCard(questionComponent.current("DATA_TYPE_QUES"))
        addToQuestion(questionComponent.current("DATA_TYPE_QUES"))
    }

    return (
        <div>
            {
                !startButtonIsClicked && !hereButtonIsClicked ?
                    <HomePage onStartButtonClicked={onStartButtonClick} onHereButtonClicked={onHereButtonClicked}/>
                    :
                    <Container className="center-screen">
                        <Row className="center-screen">
                            <Row>
                                {currentCard}
                            </Row>
                        </Row>
                    </Container>
            }
        </div>
    )
}

export default App;
