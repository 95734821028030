
export const questionData = {
    "AIM_OF_PROJECT_QUES": "What is the aim of your project?",
    "MEASURING_QUES": "What are you measuring?",
    "COMPLETED_DATA_COLLECTION_QUES": "Have you completed your data collection?",
    "COMPLETE_DATA_COLLECTION": "Complete your data collection",
    "DATA_TYPE_QUES": "What type of data do you have?",
    "SELECT_DATA_TYPE": "Select a data type",
    "NORMALLY_DISTRIBUTED_QUES": "Is your data normally distributed?",
    "DO_PARAMETRIC_TEST": "You will perform a parametric test.",
    "DO_NON_PARAMETRIC_TEST": "You will perform a non parametric test",
    "DO_NORMALITY_TEST": "Perform a normality test",
    "GROUP_COUNT_QUES": "How many groups (subsets) of variables do you have to compare?",
    "DO_CHI_SQUARE_DIFFERENCE_TEST": "Perform a Chi square test for difference (Test for Goodness if fit)",
    "DO_CHI_SQUARE_ASSOCIATION_TEST": "Perform a Chi square test for Association (Test of Independence)",
    "QUALITATIVE_OPTIONS": "I have qualitative data",
    "QUALITATIVE_ASSOCIATION_OPTIONS": "Qualitative association options",
    "DO_REGRESSION_TEST": "Perform a logistic regression test",
    "DO_T-TEST": "You will perform a T-test",
    "TYPE_OF_VARIABLES_QUES": "What type of variables does your data have?",
    "HOW_MANY_INDEPENDENT_VARIABLES_QUES": "How many independent variables does your data have?",
    "DATA_DEPENDENT_QUES": "Was your data dependent?",
    "VARIABLES_DEPENDANT_QUES": "Are the variables in your group dependant?",
    "ARE_YOU_MEASURING_OVER_TIME": "Are you measuring a variable repeatedly over time?",
    "DO_DEPENDENT_T-TEST": "Perform a dependent T-test (paired T-Test)",
    "DO_INDEPENDENT_T-TEST": "Perform an independent T-test",
    "DO_MANN_WHITNEY_TEST": "Perform a Mann-Whitney U Test",
    "DO_WILCOXON_TEST": "Perform a Wilcoxon Signed rank Test",
    "DO_FRIEDMAN_TEST": "Perform a Friedman Test",
    "DO_KRUSKAL-WALLACE_TEST": "Perform a Kruskal-Wallace Test",
    "DO_ANOVA": "You will perform an ANOVA",
    "DO_MANOVA": "You will perform an MANOVA",
    "DO_ONE_WAY_ANOVA": "Perform a One way ANOVA",
    "DO_TWO_WAY_REPEATED_ANOVA": "Perform a 2 factor repeated measures ANOVA",
    "DO_TWO_WAY_ANOVA": "Perform a 2 way ANOVA",
    "DO_REPEATED_ANOVA": "Perform a repeated measures ANOVA",
    "QUANTITATIVE_OPTIONS": "Do you have Discrete or Continuous data?",
    "MULTIPLE_DEPENDENT_VARIABLES_QUES": "Do you have multiple dependent variables?",
    "TYPES_OF_DATA": "Types of data",
    "QUALITATIVE_DATA_TYPE_EXPLANATION": "Qualitative data (Often categorical data)",
    "QUANTITATIVE_DATA_TYPE_EXPLANATION": "Quantitative data (Numerical in nature) Could be discrete or continuous",
    "DISCRETE": "Discrete",
    "CONTINUOUS": "Continuous",
    "TWO_GROUPS": "2 groups",
    "MORE_THAN_TWO_GROUPS": "> 2 groups",
    "P_GREATER_THAN_0.05": "P > 0.05",
    "P_LESS_THAN_0.05": "P < 0.05",
    "COMPLETE": "Complete",
    "OBTAIN_P_VALUE": "Obtain a p-value from your analysis",
    "NO_SIGNIFICANT_DIFFERENCE": "There is no statistical significant difference between your groups of data. Statistically they are of the same value!",
    "SIGNIFICANT_DIFFERENCE": "There is a statistical significant difference between your groups of data. Statistically they are different! Where is the difference?"
}
export const buttonText = {
    "YES": "Yes",
    "NO": "No",
    "NEXT": "Next",
    "BACK": "Back",
    "ONE": "1",
    "TWO": "2",
    "INDEPENDENT": "Independent",
    "DEPENDENT": "Dependent",
    "P_GREATER_THAN_0.05": "P > 0.05",
    "P_LESS_THAN_0.05": "P < 0.05",
    "QUANTITATIVE": "Quantitative",
    "QUALITATIVE": "Qualitative",
    "I_DONT_KNOW": "I don't know",
    "DISCRETE": "Discrete",
    "CONTINUOUS": "Continuous",
    "TWO_GROUPS": "2 groups",
    "MORE_THAN_TWO_GROUPS": "> 2 groups",
    "DETERMINING_ASSOCIATION": "I am determining the association between my data and another set of data",
    "MAKING_COMPARISON": "I am making a comparison to a pre-determined outcome",
    "COMPARING_CATEGORICAL": "Your comparison is with another set of categorical data",
    "INVESTIGATING_ASSOCIATION": "You are investigating the association with another set of measurements OR Ranks (a dependent and 1 or more independent variables)",

}